export default class IncomingCallPopup {

  constructor($scope, $uibModalInstance, $state, XucPhoneEventListener, XucPhoneState,incomingCallNumber, incomingCallName, uniqueId, callType, requestId, username, token, CtiProxy, webRtcAudio, JitsiProxy) {
    var _closing = false;
    $scope.number = incomingCallNumber;
    $scope.name = incomingCallName;
    $scope.uniqueId = uniqueId;
    $scope.calls = XucPhoneState.getCalls();
    $scope.callType = callType;
    $scope.requestId = requestId;
    $scope.username = username;
    $scope.token = token;
    $scope.maxAnswerableCalls = CtiProxy.getMaxAnswerableCalls();
    $scope.currentDevice = CtiProxy.getDeviceVendor();
    
    $scope.displayMessage = function() {
      if ($scope.currentDevice === 'Yealink' && $scope.calls.length > $scope.maxAnswerableCalls) {
        return true;
      }
    };

    $scope.isVideoCall = function() {
      return callType == 'video';
    };

    $scope.styleVideoPopupHeader = function() {
      return $scope.isVideoCall() ? 'title-icon-video' : '';
    };
    
    $scope.hideAnswer = function() {
      if($scope.currentDevice === 'Yealink' && $scope.calls.length > 1 ) {  
        return true;
      }
    };

    $scope.centerHangupButton = function() {
      if ($scope.hideAnswer()) {
        return {
          'display': 'flex',
          'justify-content': 'center' 
        };
      }
    };

    $scope.accept = function() {
      _closing = true;
      if ($scope.isVideoCall()) {
        JitsiProxy.acceptInvitation($scope.requestId, $scope.username, $scope.token);
        
        XucPhoneState.getCallsNotOnHold().forEach(call => {
          CtiProxy.hold(call.uniqueId);
        }); 
      }
      else {
        if (JitsiProxy.videoIsOngoing()) JitsiProxy.muteMicrophoneInJitsi();
        CtiProxy.answer($scope.uniqueId);
      }
      $uibModalInstance.dismiss('accept');
    };

    $scope.decline = function() {
      _closing = true;
      if ($scope.isVideoCall()) {
        JitsiProxy.rejectInvitation($scope.requestId, $scope.username);
      } else {
        CtiProxy.hangup($scope.uniqueId);
      }
      $uibModalInstance.dismiss('decline');
    };

    $scope.cancel = function() {
      _closing = true;
      webRtcAudio.stopRingtones();
      $uibModalInstance.dismiss('cancel');
    };

    var _autoClose = function() {
      if(!_closing) {
        _closing = true;
        $uibModalInstance.dismiss('autoclose');
      }
    };

    XucPhoneEventListener.addEstablishedHandler($scope, _autoClose);
    XucPhoneEventListener.addReleasedHandler($scope, _autoClose);

  }
}
