export default class KeyboardPopup {

  constructor($scope, $uibModalInstance, CtiProxy, $document, XucPhoneEventListener) {
    $scope.keyboard = [
      ['1', '2', '3'],
      ['4', '5', '6'],
      ['7', '8', '9'],
      ['*', '0', '#']
    ];

    $uibModalInstance.opened.then(function() {
      $scope.modalOpened = true;
    });

    $uibModalInstance.result.then(function() {
      $scope.modalOpened = false;
    }, function() {
      $scope.modalOpened = false;
    });

    $scope.toSrc = function(key) {
      switch (key) {
      case '*':
        return 'star';
      case '#':
        return 'sharp';
      default:
        return key;
      }
    };

    $scope.keysHistory = "";

    $scope.keyPressed = function(key) {
      CtiProxy.dtmf(key);
      $scope.keysHistory += key;
    };

    var _closeKeypad = function() {
      if ($scope.modalOpened) {
        $uibModalInstance.close();
        $scope.keysHistory = "";
      }
    };

    $scope.close = _closeKeypad;

    var validKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];

    $document.bind('keydown', function(event) {
      if (!$scope.modalOpened) {
        return;
      }
      var key = event.key;
      if (key === 'Escape') {
        $scope.close();
      } else if (validKeys.indexOf(key) > -1) {
        $scope.keyPressed(key);
      }
    });

    $scope.init = function() {
      $scope.modalOpened = false;
      XucPhoneEventListener.addReleasedHandler($scope, _closeKeypad);
    };

    $scope.init();
  }
}