import moment from 'moment';

export default function elapsedTimeFrom($interval) {
  return function(scope, element, attrs) {
    var startTime, stopTime;

    function updateTime() {
      if(typeof(startTime) !== "undefined") {
        var duration = moment().diff(moment(startTime));
        element.text(moment.utc(duration).format("HH:mm:ss"));
      } else {
        element.text("--:--:--");
      }
    }

    scope.$watch(attrs.elapsedTimeFrom, function(value) {
      startTime = value;
      updateTime();
    });

    stopTime = $interval(updateTime, 1000);

    element.bind('$destroy', function() {
      $interval.cancel(stopTime);
    });

  };
}

