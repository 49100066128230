import _ from 'lodash';

const forwardFormatError = 'FORWARD_ERROR_MSG';
const forwardUnsetError = 'FORWARD_UNSET_MSG';

export default class CallMgtModalController {

  constructor($scope, $uibModalInstance, forward, XucUtils, XucUser, CtiProxy, electronWrapper, XucPhoneState){
    this.$scope = $scope;
    this.forward = forward;
    this.XucUtils = XucUtils;
    this.CtiProxy = CtiProxy;
    this.electronWrapper = electronWrapper;
    this.XucPhoneState = XucPhoneState;
    this.uaDeviceSelected = CtiProxy.isUsingWebRtc() ? 'webrtc' : 'phone';
    this.calls = XucPhoneState.getCalls();

    $scope.uncFwdError = false;
    $scope.naFwdError = false;
    $scope.uncFwdMessage = forwardFormatError;
    $scope.naFwdMessage = forwardFormatError;
    $scope.currentUser = _.clone(XucUser.getUser());

    if ($scope.currentUser.uncFwdDestination === "" ||
      $scope.currentUser.uncFwdDestination === "null") {
      $scope.currentUser.uncFwdDestination = "";
    }

    if ($scope.currentUser.naFwdDestination === "" ||
      $scope.currentUser.naFwdDestination === "null")  {
      $scope.currentUser.naFwdDestination = "";
    }

    $scope.close = () => { $uibModalInstance.close(); };

    $uibModalInstance.result.then(() => {}, () => {
      $scope.close();
    });

    $scope.$watch(() => {
      return this.uaDeviceSelected;
    }, (newValue, oldValue) => {
      if (oldValue !== undefined && oldValue !== newValue) {
        CtiProxy.toggleUniqueAccountDevice(newValue);
      }
    });

    $scope.$on(CtiProxy.SWITCH_TO_PHONE, () => {
      this.uaDeviceSelected = 'phone';
    });
  }

  isUniqueAccount() {
    return this.CtiProxy.isUsingUa();
  }

  checkInputValue(inputType, user) {
    switch (inputType) {
    case 'uncFwd' :
      if (user.uncFwdDestination === "" || !user.uncFwdDestination) {
        user.uncFwdEnabled = false;
        this.$scope.uncFwdError = true;
        this.setElementRed("forward-unc");
        this.$scope.uncFwdMessage = forwardUnsetError;
        this.forward.setUncFwd(user);
      } else if (!this.validatePhoneNb(user.uncFwdDestination)) {
        this.$scope.uncFwdMessage = forwardFormatError;
        this.$scope.uncFwdError = true;
        this.setElementRed("forward-unc");
      } else {
        this.forward.setUncFwd(user);
        if (this.$scope.uncFwdError === true) {
          this.removeElementRed("forward-unc");
          this.$scope.uncFwdError = false;
        }
      }
      break;
    case 'naFwd':
      if (user.naFwdDestination === "" || !user.naFwdDestination) {
        user.naFwdEnabled = false;
        this.$scope.naFwdError = true;
        this.setElementRed("forward-na");
        this.$scope.naFwdMessage = forwardUnsetError;
        this.forward.setNaFwd(user);
      } else if (!this.validatePhoneNb(user.naFwdDestination)) {
        this.$scope.naFwdMessage = forwardFormatError;
        this.$scope.naFwdError = true;
        this.setElementRed("forward-na");
      } else {
        this.forward.setNaFwd(user);
        if (this.$scope.naFwdError === true) {
          this.$scope.naFwdError = false;
          this.removeElementRed("forward-na");
        }
      }
      break;
    }
  }

  processNaFwd (event, user) {
    if (user.naFwdDestination === "" || !user.naFwdDestination) {
      event.preventDefault();
      this.$scope.naFwdError = true;
      this.setElementRed("forward-na");
      this.$scope.naFwdMessage = forwardUnsetError;
    } else if (!this.validatePhoneNb(user.naFwdDestination)) {
      event.preventDefault();
    } else {
      if (user.uncFwdEnabled) this.toggle('uncFwd',user);
      this.toggle('naFwd',user);
      this.processForward(user);
    }
  }

  processUncFwd (event, user) {
    if (user.uncFwdDestination === "" || !user.uncFwdDestination) {
      event.preventDefault();
      this.$scope.uncFwdError = true;
      this.setElementRed("forward-unc");
      this.$scope.uncFwdMessage = forwardUnsetError;
    } else if (!this.validatePhoneNb(user.uncFwdDestination)) {
      event.preventDefault();
    } else {
      if (user.naFwdEnabled) this.toggle('naFwd',user);
      this.toggle('uncFwd', user);
      this.processForward(user);
    }
  }

  processForward (user) {
    this.forward.setForward(_.clone(user));
  }

  toggle (inputType, user) {
    switch (inputType) {
    case 'uncFwd' :
      user.uncFwdEnabled = !user.uncFwdEnabled;
      break;
    case 'naFwd':
      user.naFwdEnabled = !user.naFwdEnabled;
      break;
    }
  }

  processDnd(user) {
    this.forward.setDnd(user);
  }

  validatePhoneNb(phoneNb) {
    if (!phoneNb || phoneNb === '') return false;
    return this.XucUtils.isaPhoneNb(this.XucUtils.normalizePhoneNb(phoneNb));
  }

  setElementRed (elementClass) {
    let inputElement = angular.element(document.querySelector(`.${elementClass}`));
    inputElement.addClass('red-input');
  }

  removeElementRed (elementClass) {
    let inputElement = angular.element(document.querySelector(`.${elementClass}`));
    inputElement.removeClass('red-input');
  }

  setUaDeviceSelected(val) {
    this.uaDeviceSelected = val;
  }

  isElectron() {
    return this.electronWrapper.isElectron();
  }

  generateSvgName(icon) {
    let val = icon === 'webrtc' ? 'webrtc' : 'phone'; 
    if (this.uaDeviceSelected === val) {
      return icon + '_logo_hover.svg';
    } else {
      return icon + '_logo.svg';
    }
  }

  canSwitchDevice() {
    return this.calls.length === 0;
  }
}

