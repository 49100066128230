import _ from 'lodash';

export default function callControlActions($rootScope, CtiProxy, XucAgentUser, keyboard, XucSwitchboard, XucPhoneState, applicationConfiguration, JitsiProxy) {
  const MICROPHONE_MUTED_ACTION = 'microphoneMuted';
  const MICROPHONE_UNMUTED_ACTION = 'microphoneUnMuted';

  const _getPossibleActions = () => {
    const _trigger = (action, call, attr) => {
      if (call) { action(_.get(call, attr)); } else action();
    };

    return {
      hold: {
        img: 'pause',
        label: 'hold',
        trigger: (call) => { _trigger(CtiProxy.hold, call, 'uniqueId'); }
      },
      unhold: {
        img: 'play',
        label: 'unhold',
        trigger: (call) => { _trigger(CtiProxy.hold, call, 'uniqueId'); }
      },
      answer: {
        img: 'call_answer',
        label: 'answer',
        trigger: (call) => {
          if (JitsiProxy.videoIsOngoing()) JitsiProxy.muteMicrophoneInJitsi();
          _trigger(CtiProxy.answer, call, 'uniqueId');
        }
      },
      hangup: {
        img: 'call_hangup',
        label: 'hangup',
        trigger: (call) => { _trigger(CtiProxy.hangup, call, 'uniqueId'); }
      },
      conference: {
        img: 'conference_start',
        label: 'conference',
        trigger: () => { CtiProxy.conference(); }
      },
      transfer: {
        img: 'transfer_start',
        label: 'transfer',
        trigger: () => { CtiProxy.completeTransfer(); }
      },
      switchboardhold: {
        img: 'switchboardhold',
        label: 'switchboardhold',
        trigger: () => { CtiProxy.directTransfer(XucSwitchboard.getHoldQueue().number); }
      }, 
      startrecording: {
        img: 'rec',
        label: 'startrecording',
        trigger: () => { XucAgentUser.monitorUnpause(); }
      },
      stoprecording: {
        img: 'rec_selected',
        label: 'stoprecording',
        trigger: () => { XucAgentUser.monitorPause(); }
      },
      dtmfkeypad: {
        img: 'dtmf',
        label: 'dtmfkeypad',
        trigger: () => { keyboard.show(); }
      },
      mute: {
        img: 'mute',
        label: 'muteself',
        trigger: (call) => {
          CtiProxy.toggleMicrophone(call.uniqueId);
          $rootScope.$broadcast(MICROPHONE_MUTED_ACTION);
        }
      },
      unmute: {
        img: 'unmute',
        label: 'unmuteself',
        trigger: (call) => {
          CtiProxy.toggleMicrophone(call.uniqueId);
          $rootScope.$broadcast(MICROPHONE_UNMUTED_ACTION);
        }
      }
    };
  };
  const possibleActions = _getPossibleActions();

  const _getLineActions = (callState, isMuted, isRecording, canRecord) => {
    const isSwitchboard = applicationConfiguration.getCurrentAppConfig().switchboard;
    const allCalls = XucPhoneState.getCalls();
    let actions = [];

    switch(callState) {
    case XucPhoneState.STATE_RINGING:
      if(!CtiProxy.isCustomLine()) {
        actions.push(possibleActions.answer);
        actions.push(possibleActions.hangup);
      }
      break;

    case XucPhoneState.STATE_DIALING:
      actions.push(possibleActions.hangup);
      break;

    case XucPhoneState.STATE_ESTABLISHED:
      if (CtiProxy.isUsingWebRtc()){
        if (isMuted) {
          actions.push(possibleActions.unmute);
        } else {
          actions.push(possibleActions.mute);
        }
        actions.push(possibleActions.hold);
        actions.push(possibleActions.dtmfkeypad);
      } else {
        if(allCalls.length == 1 && !CtiProxy.isCustomLine()) {
          actions.push(possibleActions.hold);
        }
      }
      if (isSwitchboard) {
        actions.push(possibleActions.switchboardhold);
      }
      if(canRecord) {
        if(isRecording) {
          actions.push(possibleActions.stoprecording);
        } else {
          actions.push(possibleActions.startrecording);
        }
      }
      actions.push(possibleActions.hangup);
      break;

    case XucPhoneState.STATE_ONHOLD:
      if (CtiProxy.isUsingWebRtc()){
        actions.push(possibleActions.unhold);
      } else {
        if(allCalls.length === 1 && !CtiProxy.isCustomLine()) {
          actions.push(possibleActions.unhold);
        }
      }
      if(allCalls.length == 2 && !CtiProxy.isCustomLine()) {
        actions.push(possibleActions.transfer);
        if (CtiProxy.isConferenceCapable()){
          actions.push(possibleActions.conference);
        }
      }
      break;
    }
    return actions;
  };

  const _getDeviceConferenceActions = (callState, isRecording, canRecord) => {
    let actions = [];

    switch(callState) {
    case XucPhoneState.STATE_ESTABLISHED:
      if(canRecord) {
        if(isRecording) {
          actions.push(possibleActions.stoprecording);
        } else {
          actions.push(possibleActions.startrecording);
        }
      }
      actions.push(possibleActions.hold);
      actions.push(possibleActions.hangup);
      break;
    }

    return actions;
  };

  return {
    getLineActions: _getLineActions,
    getDeviceConferenceActions: _getDeviceConferenceActions,
    MICROPHONE_MUTED_ACTION: MICROPHONE_MUTED_ACTION,
    MICROPHONE_UNMUTED_ACTION: MICROPHONE_UNMUTED_ACTION
  };
}