import moment from 'moment';

export default function XucNotification($log, $rootScope, XucLink, XucPhoneEventListener, XucPhoneState, webNotification, $translate, electronWrapper, onHoldNotifier, remoteConfiguration, XucChat) {

  var _notifications = [];

  var _isCallNotificationEnabled = false;
  var _onHoldNotificationTime = 0;

  var _isChatNotificationEnabled = false;

  var _onNotificationClick = function() {
    try {
      electronWrapper.setFocus();
      window.focus();
      _autoClose();
    } catch(ex) {
      $log.debug("Ignoring error when focusing window", ex);
    }
  };
  
  var _onRinging = function(event) {
    $log.debug("_onRinging ", event);
    if(_isCallNotificationEnabled) {
      var options = {
        body: event.otherDName ? event.otherDName + ' ' + event.otherDN : event.otherDN,
        icon: "/assets/images/incoming_call.ico",
        onClick: _onNotificationClick,
        autoClose: 0
      };
      webNotification.showNotification($translate.instant('IncomingNotificationTitle'), options, _onNotificationShown);
    }
  };

  var _onHold = function(event) {
    $log.debug("_onHold ", event);
    if(_isCallNotificationEnabled && _onHoldNotificationTime > 0) {
      onHoldNotifier.notify(_onHoldNotificationTime*1000, event).finally(
        () => {_autoClose();},
        (notify) => {
          var options = {
            body: notify.call.otherDName ? notify.call.otherDName + ' ' + notify.call.otherDN : notify.call.otherDN,
            icon: "/assets/images/on_hold.ico",
            onClick: _onNotificationClick,
            autoClose: 0
          };
          var timer = moment.duration(notify.count*_onHoldNotificationTime, 'seconds').format("h _, m _, s _", {trim: 'all'});
          webNotification.showNotification(
            $translate.instant('OnHoldNotificationTitle')+" "+timer,
            options, _onNotificationShown
          );
          $rootScope.$broadcast('OnHoldNotification', options.body, timer, notify.call.uniqueId);
        }
      );
    }
  };

  var _onNotificationShown = function(error, hide) {
    if(error) {
      $log.debug('XucNotification error: ' + error.message);
    } else {
      _notifications.push(hide);
    }
  };

  var _onMessageReceived = (message) => {
    if(_isChatNotificationEnabled) {
      var options = {
        body: message.content,
        icon: "/assets/images/new_chat.ico",
        onClick: _onNotificationClick,
        autoClose: 0
      };
      webNotification.showNotification($translate.instant('ReceivedMessage'), options, _onNotificationShown);
    }
  };

  var _autoClose = function() {
    onHoldNotifier.stop();
    angular.forEach(_notifications, function(hide) { hide(); });
    _notifications.length = 0;
  };

  var _enableCallNotification = function() {
    _isCallNotificationEnabled = true;
    remoteConfiguration.getIntOrElse('notifyOnHold', 0).then((value) => {
      _onHoldNotificationTime = value;
    });
    webNotification.allowRequest = false;
    if(!webNotification.permissionGranted) {
      window.Notification.requestPermission();
    }
  };

  var _enableChatNotification = function() {
    _isChatNotificationEnabled = true;
    webNotification.allowRequest = false;
    if(!webNotification.permissionGranted) {
      window.Notification.requestPermission();
    }
  };

  var _disableCallNotification = function() {
    _isCallNotificationEnabled = false;
    _onHoldNotificationTime = 0;
  };

  var _disableChatNotification = function() {
    _isChatNotificationEnabled = false;
  };

  var _getCallNotificationEnabled = function() { return _isCallNotificationEnabled; };
  var _getChatNotificationEnabled = function() { return _isChatNotificationEnabled; };

  var _unInit = function() {
    $log.info('Unloading XucNotification service');
    _autoClose();
    XucLink.whenLogged().then(_init);
  };

  var _init = function() {
    $log.info('Starting XucNotification service');
    remoteConfiguration.getIntOrElse('notifyOnHold', 0).then((value) => {
      _onHoldNotificationTime = value;
      XucPhoneState.getCallsOnHold().map(_onHold);
    });
    XucLink.whenLoggedOut().then(_unInit);
  };

  $rootScope.$on(XucChat.CHAT_RECEIVED_MESSAGE, (event, message, remoteParty, isUnread) => {
    if (isUnread) _onMessageReceived(message);
  });

  XucPhoneEventListener.addRingingHandler($rootScope, _onRinging);
  XucPhoneEventListener.addOnHoldHandler($rootScope, _onHold);
  XucPhoneEventListener.addEstablishedHandler($rootScope, _autoClose);
  XucPhoneEventListener.addReleasedHandler($rootScope, _autoClose);

  XucLink.whenLogged().then(_init);

  return {
    enableCallNotification: _enableCallNotification,
    enableChatNotification: _enableChatNotification,
    disableCallNotification: _disableCallNotification,
    disableChatNotification: _disableChatNotification,
    isCallNotificationEnabled: _getCallNotificationEnabled,
    isChatNotificationEnabled: _getChatNotificationEnabled
  };
}
