export default function volumeMeter() {

  return {
    restrict: 'E',
    templateUrl: 'assets/javascripts/xchelper/directives/volumeMeter.html',
    scope: {
      sipCallId: '@',
      show: '=',
      isMuted: '=',
      micActivityCallback: '&',
      callState: '='
    },
    controller: ($scope, processVolume, $log, $rootScope, XucPhoneState) => {
      $scope.id = $scope.micOrAudio + $scope.sipCallId;

      $scope.$watch('callState', (newState) => {
        if (newState == XucPhoneState.STATE_ESTABLISHED) processVolume.monitorCall($scope.sipCallId);
      });

      if ($scope.callState == XucPhoneState.STATE_ESTABLISHED) processVolume.monitorCall($scope.sipCallId);

      $scope.inputVolume = 0;
      $scope.outputVolume = 0;

      $scope.inputProgressBarClasses = () => {
        let classes = 'progress-bar';
        if (!$scope.isMuted) classes += ' dark-background-color ';
        if ($scope.inputVolume == 0 && $scope.isMuted) classes += ' gray-background-color ';
        if ($scope.inputVolume != 0 && $scope.isMuted) classes += ' warning-background-color';
        return classes;
      };

      $scope.outputProgressBarClasses = () => {
        let classes = 'progress-bar background-primary-color';
        return classes;
      };

      $scope.inputIconClasses = () => {
        let classes = 'fa';
        if (!$scope.isMuted) classes += ' fa-microphone dark-color ';
        if ($scope.isMuted) classes += ' fa-microphone-slash ';
        if ($scope.inputVolume == 0 && $scope.isMuted) classes += ' gray-color ';
        if ($scope.inputVolume != 0 && $scope.isMuted) classes += ' warning-color blinking';
        return classes;
      };

      $scope.outputIconClasses = () => {
        let classes = 'fa fa-volume-up primary-color';
        return classes;
      };

      $rootScope.$on(processVolume.EVENT_INPUT_VOLUME, (event, data) => {
        if (data.sipCallId == $scope.sipCallId) {
          $scope.inputVolume = data.volume;
        }
      });

      $rootScope.$on(processVolume.EVENT_OUTPUT_VOLUME, (event, data) => {
        if (data.sipCallId == $scope.sipCallId) {
          $scope.outputVolume = data.volume;
        }
      });

      $scope.processVolumeData = function(volumeData) {
        $scope.volume = volumeData;
      };

      $scope.unregister = function() {
        processVolume.unmonitorCall($scope.sipCallId);
      };
      
      $scope.$on('$destroy', $scope.unregister);
    }
  };
}
