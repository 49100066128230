import _ from 'lodash';

export default class ContactsController {

  constructor($rootScope, $scope, $timeout, $state, $stateParams, XucDirectory, callContext) {

    $scope.$on('searchResultUpdated', function() {
      $scope.searchResult = _.each(XucDirectory.getSearchResult(), function(contact) {
        return contact.entry[0].toLowerCase();
      });
      $scope.headers = XucDirectory.getHeaders().slice(0, 5);
      if (!$rootScope.$$phase) $scope.$apply();
    });

    $scope.$on('favoritesUpdated', function() {
      $scope.favorites = _.each(XucDirectory.getFavorites(), function(contact) {
        return contact.entry[0].toLowerCase();
      });
      $scope.headers = XucDirectory.getHeaders().slice(0, 5);
      if (!$rootScope.$$phase) $scope.$apply();
    });

    $scope.isEmpty = function(field) {
      if (typeof field != 'undefined') {
        if (field.length > 0) {
          return true;
        }
      }
      return false;
    };

    $scope.$on('DirectoryLookUp', function(event, term) {
      $scope.search(term);
    });

    $scope.search = function(term) {
      Cti.directoryLookUp(term);
      $scope.showSearch = true;
    };

    $scope.getFavorites = function() {
      Cti.getFavorites();
    };

    $scope.addFavorite = function(contact) {
      Cti.addFavorite(contact.contact_id, contact.source);
    };

    $scope.removeFavorite = function(contact) {
      Cti.removeFavorite(contact.contact_id, contact.source);
    };

    $scope.init = function() {
      if ($stateParams.showFavorites === false) {
        $scope.showSearch = true;
      } else {
        $scope.getFavorites();
        $scope.showSearch = false;
      }

      if (typeof($stateParams.search) != 'undefined') {
        $scope.search($stateParams.search);
      }

    };

    $scope.isCallable = function(contact) {
      return _.some([1, 2, 3, 5], function(index) {
        return !_.isEmpty(contact.entry[index]);
      }) || callContext.isMeetingRoom(contact);
    };

    $scope.isMeetingRoom = function (contact) {
      return callContext.isMeetingRoom(contact);
    };

    $scope.isHoveringPersonalMeetingRoom = function (contact) {
      return contact.hover && contact.personal && $scope.isMeetingRoom(contact);
    };

    $scope.isHoveringPersonalContact = function (contact) {
      return contact.hover && contact.personal && !$scope.isMeetingRoom(contact);
    };

    $scope.init();
  }
}
