export default function callRemoteParty(callContext) {
  return {
    restrict: 'E',
    templateUrl: 'assets/javascripts/xcchat/directives/callRemoteParty.html',
    scope: {
      remoteParty: '@'
    },
    link: (scope) => {

      scope.callRemoteParty = () => {
        callContext.dialByUsername(scope.remoteParty);
      };

    }
  };
}
