export default class LoginController {

  constructor($scope, $state, $stateParams, $window, XucVoiceMail, CtiProxy, electronWrapper, remoteConfiguration) {

    remoteConfiguration.getBooleanOrElse('showAppDownload', true).then((resp) => {
      $scope.displayDesktopDownload = resp;
    });

    $scope.error = $stateParams.error;
    CtiProxy.stopUsingWebRtc();

    $scope.onLogin = function() {
      $state.go('interface.favorites', {
        'showFavorites': true
      });
    };

    $scope.displayDownloadDesktop = function() {
      return !electronWrapper.isElectron() && $scope.displayDesktopDownload == true;
    };

    $scope.hostAndPort = $window.externalConfig.hostAndPort;
    $scope.useSso = $window.externalConfig.useSso;
    $scope.casServerUrl = $window.externalConfig.casServerUrl;
    $scope.casLogoutEnable = $window.externalConfig.casLogoutEnable;
    $scope.openidServerUrl = $window.externalConfig.openidServerUrl;
    $scope.openidClientId = $window.externalConfig.openidClientId;
  }
}
